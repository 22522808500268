.root {

}

.name_description_container {
  transition: 0.7s;
}

.compact {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  transition: 0.7s;
  height: fit-content;

  :global {
    & .name_container {
      transition: 0.7s;
      min-width:60%;
      width: 60%;
    }

    & .description_container {
      transition: 0.7s;
      min-width:calc(40% - 1rem);
      width: calc(40% - 1rem);
    }
  }
}

.large {
  display: flex;
  gap: 1rem;
  flex-direction: row;
  flex-wrap: wrap;
  transition: 0.7s;

  :global {
    & .name_container {
      transition: 0.7s;
      min-width:70%;
      width: 70%;
    }

    & .description_container {
      transition: 0.7s;
      min-width:70%;
      width: 70%;
    }
  }
}