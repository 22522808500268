.drawer > :global(.MuiDrawer-paper) {
  top: 96px;
  height: calc(100vh - var(--header-height));
  border-radius: unset;
}

.drawer-header {
  & > p {
    padding-left: .75em;
    color: var(--label-text-color);
    display: none;
    opacity: 0;
  }
  &:global(.open) > p {
    display: block;
    opacity: 1;
  }
  & > button {
    margin: 0 .5em;
    padding: 0;
  }
}

.drawer .menu-item {
  padding: 6px .5em;

  &.submenu > a {
    background-color: transparent;
  }

  & > a, & > div {
    min-height: 48px;
    min-width: 36px;
    padding: 0 .5em;
    background-color: var(--input-background);
    border-color: var(--list-element-border-color);
    border-radius: .5em;
    justify-content: center;

    &:global(.expand) {
      justify-content: initial;

      & .icon {
        margin-right: 1em;
      }

      & .label {
        display: block;
        opacity: 1;
      }

      & .end-icon {
        display: inline-flex;
      }
    }

    &:global(.active) {
      background-color: var(--primary-color);
    }

    &:global(.active2) {
      background-color: var(--dark-primary-color);
    }

    & .icon {
      justify-content: center;
    }

    & .label {
      opacity: 0;
      flex: 1;
    }

    & .end-icon {
      min-width: 14px;
      display: none;
    }
  }
}