.connectList {
  display: flex;
  flex-direction: row!important;
  gap: 0.5rem;
  flex-wrap: wrap;
  width: 10px;
  flex: 1;
  overflow-x: hidden;
  padding-right: 16px!important;
}

.connectCreator {
  margin-top: -20px!important;
  margin-bottom: -20px!important;
  align-self: center;
}

.connect {
  max-width: 100%;
}

.rootSmall {
  max-height: 100%;
  overflow-y: auto;
  & ul {
    padding-top: 20px!important;
    padding-bottom: 20px!important;
  }
}

