.root {
  background-color: var(--paper-color);

  border: 1px solid var(--line-color);
  border-radius: 8px;

  padding: 32px;

  .title {
    font-weight: 900;
    font-size: 1.125rem;
  }
}