.root {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  gap: 5px;
  padding: 4px;

  max-width: 316px;
  width: 100%;

  background: #CFC8FF;

  border: 2px solid #CFC8FF;
  border-radius: 12px;

  pointer-events: all;

  position: relative;
  z-index: 1100;

  .header {
    padding: 16px 20px;
    width: 100%;

    .title {
      font-weight: 900;
      font-size: 1rem;
      color: var(--paper-color);
    }

    .buttonClose {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .content {
    width: 100%;

    background-color: var(--paper-color);

    border-radius: 12px;

    padding: 20px;

    .title {
      font-size: 0.875rem;
      line-height: 16px;

      color: var(--label-text-color);
    }

    .list {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      .tag {
        font-weight: 600;
        font-size: 0.75rem;
        line-height: 20px;

        color: var(--label-text-color);
        background: var(--line-color);

        border-radius: 4px;

        display: flex;
        align-items: center;
        justify-content: center;

        min-height: 20px;
        max-width: 100%;

        padding: 2px 4px;

        cursor: pointer;

        position: relative;

        .title {
          font-weight: 400;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        &:hover {
          background-color: var(--tag-hover-color);
        }
      }
    }

  }
}