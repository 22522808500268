.title {

  display: flex;
  align-items: center;
  flex-direction: column;

  gap: 4px;

  .time {
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 16px;

    color: var(--label-text-color);

    &.active {
      color: var(--text-color);
    }
  }

  .separator {
    width: 2px;
    height: 16px;

    background-color: var(--label-text-color);
  }
}
