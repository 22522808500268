.root {
    flex-basis: 100px;
    flex-grow: 1;
    padding-right: 16px;
    margin-right: -16px;
    overflow-y: auto;
    padding-bottom: 100px;
    border-radius: 8px;
}

.saveButtonPanel {
    margin: 0 -30px -30px -30px;
    width: calc(100% + 60px);
    height: 100px;
    position: absolute;
    bottom: 0;
    opacity: 0.85;
    backdrop-filter: blur(16px);
    z-index: 1200;
}

.saveButton {
    position: absolute;
    bottom: 30px;
    right: 30px;
    width: 300px;
    display: flex;
    z-index: 1201;
    opacity: 1;
}