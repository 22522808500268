@import "../../../assets/styles/mixins";

.root {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  box-sizing: border-box;

  &.error {
    background-color: var(--error-color);

    &.pulse {
      animation: animate-pulse-error 3s linear infinite;
    }
  }

  &.success {
    background-color: var(--success-color);

    &.pulse {
      animation: animate-pulse-success 3s linear infinite;
    }
  }

  &.disabled {
    background-color: transparent;
    border: 1px solid var(--list-element-border-color);
  }

  &.warning {
    background-color: var(--toastify-color-warning);

    &.pulse {
      animation: animate-pulse-success 3s linear infinite;
    }
  }

  @keyframes animate-pulse-error {
    0% {
      box-shadow: 0 0 0 0 rgba(255, 117, 76, 0.7), 0 0 0 0 rgba(255, 117, 76, 0.7);
    }
    40% {
      box-shadow: 0 0 0 10px rgba(255, 117, 76, 0), 0 0 0 0 rgba(255, 117, 76, 0.7);
    }
    80% {
      box-shadow: 0 0 0 10px rgba(255, 117, 76, 0), 0 0 0 6px rgba(255, 117, 76, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(255, 117, 76, 0), 0 0 0 6px rgba(255, 117, 76, 0);
    }
  }

  @keyframes animate-pulse-success {
    0% {
      box-shadow: 0 0 0 0 rgba(127, 186, 122, 0.7), 0 0 0 0 rgba(127, 186, 122, 0.7);
    }
    40% {
      box-shadow: 0 0 0 10px rgba(127, 186, 122, 0), 0 0 0 0 rgba(127, 186, 122, 0.7);
    }
    80% {
      box-shadow: 0 0 0 10px rgba(127, 186, 122, 0), 0 0 0 6px rgba(127, 186, 122, 0);
    }
    100% {
      box-shadow: 0 0 0 0 rgba(127, 186, 122, 0), 0 0 0 6px rgba(127, 186, 122, 0);
    }
  }
}

