.root {

}

.lineChart {

}

.notData {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}