.root {
  .action {
    color: var(--primary-color);
    cursor: pointer;
    transition: all .2s linear 0s;

    &:hover {
      color: var(--light-primary-color);
    }
  }
}