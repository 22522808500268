.root {
  margin-left: 12px;
  .diffList {
    margin-left: 8px
  }
  .elementDiffList {
    margin-left: 8px
  }
  .elementName {

  }
  .beforeText {
    color: var(--primary-color);
    opacity: 0.8;
  }

  .arrow {
    opacity: 0.5;
  }

  .afterText {
    color: var(--light-primary-color);
  }
}