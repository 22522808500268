.root {

  position: relative;
  display: flex;
  flex: auto;
  padding: 0 8px;


  .icon {
    z-index: 1000;

    cursor: pointer;

    color: var(--text-color);
  }
}

.title {
  font-weight: 900;
  font-size: 2.5rem;
}

.editor {
  border-top: 1px solid var(--label-text-color);
  border-bottom: 1px solid var(--label-text-color);
  max-height: 100vh;
  padding: 20px 0;

  span {
    font-family: Consolas, "Courier New", monospace;
  }
}