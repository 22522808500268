.root {
  background-color: var(--paper-color);
  border-right: 1px solid var(--line-color);
  min-width: 260px;
  width: 100%;
  height: 100%;

  & .title {
    margin-left: 5px;
    font-size: 12px;
    color: var(--label-text-color);
    font-weight: 500;
    line-height: 16px
  }
}

.paper {
  margin-top: .5em;
}

.tree {
  height: calc(100vh - 232px);
  width: 100%;

  & > div {
    width: 100%;
    padding-left: 20px;
    padding-right: 5px;
  }
}

.list {
  height: calc(100vh - 292px);
  display: flex;
  width: 100%;
}

.btn {
  color: var(--text-color)!important;
  background-color: var(--primary-color);
  cursor: default;
  opacity: 0.7;
  transition: all 500ms;

  &:hover {
    color: var(--text-color)!important;
    border-color: transparent!important;
  }

  &:global(.active) {
    background-color: #242731;
    cursor: pointer;
    opacity: 1;

    &:hover {
      border-color: var(--primary-color);
      box-shadow: 0 0 7px var(--primary-color);
    }
  }
}