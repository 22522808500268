.root {
  box-sizing: border-box;

  min-width: 160px;
  min-height: 60px;
  flex-grow: 1;

  border: 2px solid var(--dark-primary-color);

  border-radius: 12px;

  background-color: var(--paper-color);
  overflow: hidden;

  .header {
    padding: 5px 5px 5px 5px;
    width: 100%;

    .flowName {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-weight: 400;
      font-size: 0.8rem;
      line-height: 24px;
      opacity: 0.6;
      text-align: center;
    }

    .blockName {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 100%;
      font-size: 0.8rem;
      font-weight: 300;
      line-height: 24px;
      text-align: center;
    }
  }
}

.handle {

  &.hidden {
    visibility: hidden;
  }

  &:global(.react-flow__handle) {
    width: 12px;
    height: 12px;
    background-color: var(--edge-color);
    z-index: 1000;
    border: unset;

    &:global(.react-flow__handle-right) {
      right: -5px;
    }

    &:global(.react-flow__handle-left) {
      left: -5px;
    }
  }
}

.selected {
  filter: drop-shadow(0 0 35px var(--dark-primary-color));
}

.dropdown {
  z-index: 1350;
}
