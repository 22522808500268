.root {
  min-width: max-content;

  .dots {
    font-size: 1rem;
    color: #797a7e;
  }

  & :global(.MuiPagination-root) {
    font-family: "Inter", sans-serif;
  }

  :global(.MuiPagination-ul) {
    gap: 25px;

    & button {
      font-size: 1rem;

    }
  }
}