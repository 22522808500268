
.wrapper {
  & .list {
    display: flex;
    align-items: center;
  }

  & .element {
    color: var(--label-text-color);
    padding: 8px 20px;
    border-radius: 12px;
    display: block;
    line-height: 20px;
    text-align: center;


    &:hover {
      color: var(--text-color);
    }

    &.active {
      background-color: var(--primary-color);
      color: var(--text-color);
    }
  }
}
