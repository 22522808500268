.root {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 56px;

  border: 1px solid var(--list-element-border-color);
  border-radius: 8px;

  cursor: pointer;

  &:hover {
    border-color: var(--text-color);

    & > button {
      color: var(--text-color);
      border-color: var(--text-color);
    }
  }
}

.search {
  padding-bottom: 4px;
}

.menu {
  z-index: 1500;

  padding: 20px 0;

  border-radius: 12px;
  border: 1px solid var(--list-element-border-color);

  background-color: var(--paper-color);

  max-height: 200px;
  max-width: 300px;
  width: 100%;

  overflow-y: auto;

  & > ul {
    display: flex;
    flex-direction: column;
    gap: 12px;


    & > li {
      color: var(--text-color);

      cursor: pointer;

      min-height: 24px;

      display: flex;
      align-items: center;

      line-height: 16px;

      padding: 12px 20px;

      &:hover {
        background-color: var(--list-element-border-color);
      }
    }
  }
}