.buttonSave {
  animation: animate-pulse-success 3s linear infinite;
}

@keyframes animate-pulse-success {
  0% {
    box-shadow: 0 0 0 0 #808191, 0 0 0 0 #808191;
  }
  40% {
    box-shadow: 0 0 0 10px #808191, 0 0 0 0 #808191;
  }
  80% {
    box-shadow: 0 0 0 10px #808191, 0 0 0 6px #808191;
  }
  100% {
    box-shadow: 0 0 0 0 #808191, 0 0 0 6px #808191;
  }
}