.root {
  display: flex!important;
  margin: 0.5rem!important;
  padding: 0.5rem!important;
  gap:1rem!important;
  flex-direction: column!important;

  & > * {
    overflow: unset !important;
  }
}
