.root {
  display: flex;
  flex-wrap: wrap;
  align-content: stretch;
  height: 100%;
  gap: 30px;
  overflow-y: auto;
  flex: 1
}

.page {
  display: flex;
  padding: 32px;
  height: 100%;
  position: relative;
}