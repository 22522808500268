.trigger {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 4em;
  width: 100%;
  z-index: 0;

  & button {
    position: relative;
    width: 8em;
  }
}