.button {
  width: max-content;

  padding: 8px 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.75rem;
  font-weight: 500;
  line-height: 20px;

  color: var(--text-color);

  border-radius: 12px;

  cursor: pointer;

  transition: all .2s linear 0s;

  position: relative;

  &.active {
    background-color: var(--primary-color);
  }

  &.marker {
    &.active {
      &:after {
        content: '';

        width: 4px;
        height: 4px;

        background-color: var(--error-color);

        display: block;

        position: absolute;
        right: -2px;
        top: 0;

        border-radius: 50%;
      }
    }
  }

  &:hover {
    background-color: var(--primary-color);
  }
}

.menu {
  z-index: 1500;
  position: absolute;

  padding: 20px;
  margin-top: 4px !important;

  border-radius: 12px;
  border: 1px solid var(--list-element-border-color);

  background-color: var(--paper-color);

  max-width: 460px;
  width: 100%;

  overflow-y: auto;

  .title {
    font-weight: 900;
    font-size: 1.125rem;
    line-height: 18px;
  }

  .reset {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 20px;
    color: var(--label-text-color);
    transition: all .2s linear 0s;
    cursor: pointer;

    &:hover {
      color: var(--text-color);
    }
  }
}
