.wrapper {
  display: flex;
  flex: auto;
  width: 100%;
  position: relative;
  overflow-x: hidden;

  :global(.MuiPaper-root) {
    background-color: var(--paper-color);
  }
}

.paper {
  margin: 16px !important;
  padding: 32px;
}
