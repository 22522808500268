.root {

  :global {
    .MuiPaper-root.MuiMenu-paper {
      width: 340px;
    }
    .MuiMenuItem-root {
      & > .MuiListItemIcon-root {
        width: auto;
        padding-right: 0;
      }
      &.guide > .MuiListItemIcon-root {
        padding-left: 24px;
      }
    }
  }
}