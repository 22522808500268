.handle {
  width: 50px;
  height: 30px;
  position: absolute;
  top:0;
  left:0;
  z-index: 1001;

  &:hover {
    cursor: move;
  }
}

.title {
  display: flex;
  position: relative;
  justify-content: center;

  .name {
    min-height: 21px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .settings {
    position: absolute;
    right: 0;
    top: 0;
    overflow: hidden;

    :global .ant-btn-group {

      .action-btn {
        overflow: hidden;
        width: 0;
        visibility: hidden;
        transition: visibility 0s linear 300ms, width 300ms;
      }

      .trigger-btn {
        z-index: 1100;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
      }

      transition: border 300ms;

      &.expanded {
        border: solid 1px var(--list-element-border-color);
        border-radius: .5em;

        .action-btn {
          width: 32px;
          visibility: visible;
          transition: visibility 0s linear 0s, width 300ms;
        }

        .trigger-btn {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }
}

.body {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
}
