.tooltipElement {

  &::before {
    width: 4px;
    height: 4px;

    display: inline-block;

    position: relative;

    content: '';

    background-color: var(--blue-button-color);

    margin: 0 8px 3px 0;

    border-radius: 50%;
  }
}