:root {
  --header-height: 96px;

  --primary-color: #6c5dd3;
  --dark-primary-color: #453f7e;
  --light-primary-color: #9789FB;
  --secondary-color: #1b1d21;
  --text-color: #ffffff;

  --error-color: #ff754c;
  --success-color: #7fba7a;

  --line-color: #373a43;
  --label-text-color: #808191;
  --paper-color: #242731;
  --list-element-border-color: #e4e4e419;
  --input-label-text-color: #b2b3bd;
  --edge-color: #e4e4e4;
  --tag-hover-color: #cfc8ff;
  --input-background: #2a2d36;
  --layout-color: #1F2128;
  --handle-color: #373A43;
}


body[data-theme='light'] {
  --primary-color: #6c5dd3;
  --dark-primary-color: #453f7e;
  --light-primary-color: #9789FB;
  --secondary-color: #1b1d21;
  --text-color: #ffffff;

  --error-color: #ff754c;
  --success-color: #7fba7a;

  --line-color: #373a43;
  --label-text-color: #808191;
  --paper-color: #242731;
  --list-element-border-color: #e4e4e419;
  --input-label-text-color: #b2b3bd;
  --edge-color: #e4e4e4;
  --tag-hover-color: #cfc8ff;
  --input-background: #2a2d36;
  --layout-color: #1F2128;
  --handle-color: #373A43;

}
