.root {
  text-align: center;
}

.button {
  min-width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  border: 2px solid var(--text-color);

  color: var(--text-color);
  cursor: pointer;

  &.start {
    margin-right: 20px;
  }

  &.end {
    margin-left: 20px;
  }
}