.scroller {
  overflow-x: auto;
}

.wrapper {
  position: relative;
}

.table {
  min-width: 1000px;
}

.head {
  position: sticky;
  top: 0;
  z-index: 1;
  background: #242731;
}

.head .row {
  height: 60px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  border-bottom: 1px solid #393d44;
}

.head .cell {
  padding: 20px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #b2b3bd;
}

.body {
  height: 400px;
  overflow-y: auto;
}

.body .row {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  min-height: 80px;
  border-bottom: 1px solid #393d44;
}

.body .row:hover {
  background: #242630;
}

.body .cell {
  padding: 8px;
  font-family: 'Inter', sans-serif;
  color: #ffffff;
}