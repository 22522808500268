.root {
  width: 100%;

  :global {
    .MuiTextField-root {
      background: var(--input-color);

      border-radius: 8px;

      &:focus {
        outline: 2px solid #5f75ee;
      }
    }

    .MuiOutlinedInput-root {
      //padding: 16px;
    }

    .MuiInputBase-multiline {
      padding: 14px;
    }

    .MuiOutlinedInput-input {
      font-family: Montserrat, sans-serif;
      font-size: 1rem;
      line-height: 28px;
      //padding: 16px;
      background-color: var(--input-color);
      border-radius: 8px;

      &:focus {
        background-color: var(--input-color);
      }

      &:-webkit-autofill {
        border: unset;
        -webkit-text-fill-color: var(--text-color);
        -webkit-box-shadow: 0 0 0 1000px var(--input-color) inset;
        transition: background-color 5000s ease-in-out 0s;
      }
    }

    .MuiOutlinedInput-notchedOutline {
      display: none;
    }

    .MuiInputBase-adornedEnd,
    .MuiInputBase-inputAdornedEnd {
      &.MuiOutlinedInput-root {
        padding-right: unset !important;
      }
    }

    .Mui-focused {
      border-radius: 8px;
      outline: 2px solid #5f75ee;
    }

    .Mui-disabled {
      color: gray;
      cursor: not-allowed;
    }

    .MuiInputAdornment-root {
      color: var(--label-text-color);
      margin-left: 16px;
      margin-right: 16px;
    }

    .Mui-error {
      border-radius: 8px;
      outline: 2px solid var(--error-color);
    }

    .MuiSelect-select.MuiSelect-select {
      line-height: unset;
    }

    .MuiSelect-nativeInput {
      height: 100%;
    }

    .MuiSelect-icon {
      margin: 0 12px;
    }
  }
}

.paper {
  &:global(.MuiMenu-paper) {
    margin-top: 8px;
    max-height: 40%;
    border-radius: 12px;
    transition: all 0.2s linear 0s;
  }

  :global {
    .MuiList-root {
      border-radius: 12px;

      background-color: var(--paper-color);

      display: grid;
    }

    .MuiMenuItem-root {
      background-color: var(--paper-color);

      padding: 0 20px;

      height: 48px;

      &.Mui-selected {
        background-color: var(--primary-color) !important;

        &:hover {
          background-color: var(--primary-color);
        }
      }

      &:hover {
        background-color: var(--list-element-border-color);
      }
    }

    .Mui-selected {
      background-color: var(--primary-color);

      &:hover {
        background-color: var(--primary-color);
      }
    }
  }
}

.warning {
  :global {
    .MuiTextField-root {
      background: var(--input-color);
      border-radius: 8px;

      border: 1px solid var(--toastify-color-warning);

      &:focus {
        outline: 1px solid var(--toastify-color-warning);
      }
    }

    .Mui-focused {
      border-radius: 8px;
      outline: 1px solid var(--toastify-color-warning);
    }
  }
}

.warning {
  :global {
    .MuiTextField-root {
      background: var(--input-color);
      border-radius: 8px;

      border: 1px solid var(--toastify-color-warning);

      &:focus {
        outline: 1px solid var(--toastify-color-warning);
      }
    }

    .Mui-focused {
      border-radius: 8px;
      outline: 1px solid var(--toastify-color-warning);
    }
  }
}

.labelBlock {
  display: flex;
  flex-direction: row;
}
