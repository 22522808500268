.root {
  padding: 12px 20px;

  display: flex;

  border: 1px solid var(--line-color);
  border-radius: 16px;

  &.withoutBorder {
    border: none;
  }

  &.small {
    padding: 0;
    overflow: hidden;
  }

}