.wrapper {
  position: relative;

  &.hidden {
    display: none;
  }
}

.root {
  background-color: var(--input-color);
  color: var(--text-color);
  font-family: "Montserrat", sans-serif !important;

  min-height: 56px;
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1rem;
  line-height: 20px;

  padding: 8px 16px;
  gap: 4px;

  border: 1px solid transparent;
  border-radius: 8px;

  cursor: pointer;

  .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.small {
    height: 36px;
    min-height: unset;
  }
}

.popper {
  width: 100%;
  z-index: 1000;

  :global(.Mui-selected) {
    background-color: var(--list-element-border-color);
  }
}

.listbox {
  padding: 16px;

  border-radius: 8px;
  border: 1px solid var(--list-element-border-color);

  background-color: var(--paper-color);

  overflow-x: hidden;
  overflow-y: auto;

  max-height: 30vh;


}


.option {
  color: var(--text-color);
  cursor: pointer;
  min-height: 24px;
  line-height: 16px;
  padding: 12px 20px;

  &:hover {
    background-color: var(--list-element-border-color);
    cursor: pointer;
  }

  &.noData {
    &:hover {
      background-color: transparent;
      cursor: default;
    }
  }
}