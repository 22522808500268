.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: .5rem;
  padding: .5rem .5rem 0 .5rem;

  & > .save-btn {
    width: 200px;
    color: var(--text-color);
  }
}