*,
*::before,
*::after {
  box-sizing: border-box;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

body,
h1,
h2,
h3,
h4,
p,
ul[class],
ol[class],
li,
figure,
figcaption,
blockquote,
dl,
dd,
button,
form,
input {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  font-size: inherit;
  margin: 0;
  padding: 0;
}

body {
  scroll-behavior: smooth;
}

a {
  &:not([class]) {
    text-decoration-skip-ink: auto;
  }

  &[class] {
    text-decoration: none;
  }
}

article > * + * {
  margin-top: 1em;
}

input,
button,
textarea,
select {
  font: inherit;
  outline: none;
  border: none;
  background-color: transparent;

  &:required {
    box-shadow: none;
  }
}

table {
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
}

@media (prefers-reduced-motion: reduce) {
  * {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    scroll-behavior: auto !important;
  }
}

li {
  list-style-type: none;
  padding-inline-start: unset;
}

label {
  cursor: unset;
}