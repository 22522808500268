.root {
  background-color: var(--paper-color);
  color: var(--text-color);

  border: 1px solid var(--list-element-border-color);
  border-radius: 8px;

  cursor: pointer;

  padding: 20px;

  &.active {
    background-color: var(--primary-color);
  }


  .header {

    display: flex;
    justify-content: space-between;
    gap: 8px;

    .title {
      font-size: 0.875rem;
      font-weight: 600;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
    }
  }

}
