.dateCell {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  /* identical to box height, or 143% */

  display: flex;
  align-items: center;

  color: #808191;
}

.flowCell {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  width: 100%;
  word-wrap: break-word;
}
