.upload-place {
  height: 100%;
  min-width: 242px;

  .drop-zone {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    min-height: 81px;

    border: 1px solid var(--outlined-button-color);
    border-radius: 1rem 0 0 1rem;
    border-right: none;

    cursor: pointer;

    color: var(--outlined-button-color);

    transition: all .2s linear 0s;

    &:hover {
      background-color: var(--input-background);
    }

    & .description {
      font-weight: 900;
      font-size: 1rem;
      text-align: center;
    }
  }

  .addBtn {
    width: 6.5em;
    height: 100%;
    min-height: 81px;

    border: 1px solid var(--outlined-button-color)!important;
    border-radius: 0 1rem 1rem 0!important;
    border-left: 1px solid var(--outlined-button-color);

    &:hover {
      background-color: var(--input-background);
    }

    & > :global(.anticon) {
      font-size: 3em;
      color: var(--outlined-button-color);
    }
  }


}

.uploadBtn {
  width: 140px;
}