.root {
  position: relative;
  height: calc(100vh - var(--header-height));

  background-color: var(--paper-color);

  border-left: 1px solid var(--line-color);
  z-index: 1100;


  &.header {
    height: calc(100vh - 285px) !important;
  }

  &.fullscreen {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  & .title {
    font-weight: 500;
    font-size: 1.125rem;
    line-height: 1.5rem;

    &.large {
      font-weight: 900;
      font-size: 2.5rem;
      line-height: 3.25rem;
    }
  }

  .titleWrapper {
    padding: 20px 20px 0;
  }

  .children {
    height: 100%;
    overflow: hidden;

    & > * {
      display: grid;
      height: 100%;
      gap: 16px;
      grid-template-rows: 1fr;

      & > * {
        overflow: auto;
        grid-column: 1;
      }
    }
  }

}