body[data-theme='dark'] {
  --toastify-toast-background: white;
  --toastify-toast-min-height: 50px;
  --toastify-text-color-success: var(--error-color);
  --toastify-color-progress-error: var(--error-color);
  --toastify-text-color-error: var(--success-color);
  --toastify-color-progress-success: var(--success-color)
}


.root {
  width: unset !important;

  & .toast {
    max-width: 560px;
    width: max-content;
    min-height: 50px;
    display: flex;

    background-color: var(--paper-color);

    border: 1px solid var(--line-color);
    border-radius: 8px;

    padding: 16px;
  }

  & .body {
    color: var(--text-color);
    font-weight: 600;
    max-width: 492px;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
    line-height: 18px;
    padding: unset;
  }

  & .icon {
    width: 20px;
    height: 20px;

    border-radius: 50%;
    color: var(--text-color);
    font-size: 6px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.error {
      background-color: var(--error-color);
    }

    &.success {
      background-color: var(--success-color);
    }

    &.warning {
      background-color: var(--toastify-color-warning);
    }
  }
}
