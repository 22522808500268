.buttonBlock {
  display: flex;
  flex-direction: row;
  justify-content: end;
}

.descriptor {
  display: flex;
  width: 100%;
  height: 9em;
  color: var(--text-color);
  word-break: break-all;
  overflow-y: scroll;
  -ms-user-select: all;
  -moz-user-select: all;
  -khtml-user-select: all;
  -webkit-user-select: all;
  user-select: all;
}

.large-data {
  color: var(--error-color);
}

.snackbars {
  & :global(.MuiSnackbarContent-root) {
    background-color: var(--primary-color);
    justify-content: center;
    border-radius: 16px;
    min-width: 0;

    :global(.MuiSnackbarContent-message) {
      color: var(--text-color);
      padding: 0;
    }
  }
}
.snackbar-warn {
  & :global(.MuiSnackbarContent-root) {
    background-color: var(--error-color);
    justify-content: center;
    border-radius: 16px;
    min-width: 270px;

    :global(.MuiSnackbarContent-message) {
      color: var(--text-color);
      padding: 0;
    }
  }
}
