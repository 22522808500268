.root {
  border-radius: 1rem;
  border: 1px solid var(--outlined-button-color);
  box-sizing: border-box;

  background-color: var(--paper-color);

  padding: 8px 16px;

  cursor: pointer;

  transition: all .2s linear 0s;

  position: relative;
  overflow: hidden;

  min-height: 81px;
  max-height: 400px;
  display: flex;
  flex-direction: column;


  .image {
    height: 50px;
    flex: 1;
    overflow: hidden;
    position: relative;
    & img {
      object-fit: contain;
      max-height: 100%;
    }
  }

  .ribbon {
    position: absolute;
    right: -5px;
    top: -5px;
    z-index: 1;
    overflow: hidden;
    width: 75px;
    height: 75px;
    text-align: right;
  }

  .ribbon span {
    font-size: 10px;
    font-weight: bold;
    color: #FFF;
    text-transform: uppercase;
    text-align: center;
    line-height: 20px;
    transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    width: 100px;
    display: block;
    background: #6C5DD3;
    background: linear-gradient(#6C5DD3 0%, #6C5DD3 100%);
    box-shadow: 0 3px 10px -5px rgba(0, 0, 0, 1);
    position: absolute;
    top: 19px;
    right: -21px;
  }

  .ribbon span::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid #6C5DD3;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #6C5DD3;
  }

  .ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #6C5DD3;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #6C5DD3;
  }

  .ribbon span::after {
    content: "";
    position: absolute;
    right: 0px;
    top: 100%;
    z-index: -1;
    border-left: 3px solid transparent;
    border-right: 3px solid #6C5DD3;
    border-bottom: 3px solid transparent;
    border-top: 3px solid #6C5DD3;
  }

  .title {
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .description {
    font-size: 0.75rem;
    color: var(--outlined-button-color);
  }

  &:hover {
    border: 1px solid transparent;
    background-color: var(--outlined-button-color);
  }
}