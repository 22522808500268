.root {
  .buttonN {
    .Button_root {
      max-width: 193px;
    }
  }

  .standardButton {
    max-width: 193px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .nToOneButton {
    max-width: 193px;
    margin-top: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
  }
}
