.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  position: relative;
  background-color: var(--layout-color);
  color: var(--text-color);

}

.flex {
  display: flex;
  flex: auto;
  overflow: auto;
}

.content {
  flex: auto;
}
