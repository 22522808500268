.root {
  box-sizing: border-box;

  min-width: 420px;
  min-height: 355px;
  flex-grow: 1;

  border: 2px solid var(--dark-primary-color);

  border-radius: 12px;

  background-color: var(--paper-color);
  overflow: hidden;

  .header {
    padding: 20px 20px 5px 20px;
    overflow: hidden;
    width: 100%;

    .processorName {
      text-overflow: ellipsis;
      overflow: hidden;
      font-weight: 600;
      font-size: 1rem;
      line-height: 24px;
    }

    .processorType {
      font-weight: 300;
      line-height: 24px;
    }
  }

  .tabs {
    padding: 5px 0 10px 0;
    overflow: hidden;
    width: 100%;
  }

  .downloadButton {
    margin-left: 10px;
    margin-top: -6px;
    margin-right: -10px;
    margin-bottom: -10px;
  }

  .dates {
    flex: none;
    width: 100%;
    padding: 15px;

    align-self: end;

    border-top: 1px solid var(--flow-block-bottom-border);
  }

  .text {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;

    color: var(--label-text-color);
  }


  .info {
    font-size: 0.75rem;
    line-height: 15px;

    color: var(--label-text-color);

    padding: 20px;
  }
}

.handle {

  &.hidden {
    visibility: hidden;
  }

  &:global(.react-flow__handle) {
    width: 12px;
    height: 12px;
    background-color: var(--edge-color);
    z-index: 1000;
    border: unset;

    &:global(.react-flow__handle-right) {
      right: -5px;
    }

    &:global(.react-flow__handle-left) {
      left: -5px;
    }
  }
}
