.root {
  :global {
    .data-type-label {
      font-weight: 600 !important;
      font-size: 0.875rem !important;
      color: var(--primary-color) !important;
      opacity: 1 !important;
    }

    .variable-value {
      font-size: 0.688rem;
    }

  }

  .item {
    &:before {
      position: absolute;
      content: '';
      width: 1px;
      height: max-content;
    }
  }
}