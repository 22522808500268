.root {
  &:global(.react-flow__handle) {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 20px;
    height: 20px;

    background-color: var(--edge-color);

    z-index: 1001;

    border: 2px solid var(--tag-hover-color);

    &:global(.react-flow__handle-bottom) {
      bottom: -15px;
    }
  }
}

.invisible {
  width: 0 !important;
  min-width: 0 !important;
  height: 0 !important;
  min-height: 0 !important;
  border: none 0 !important;
  visibility: hidden;
}

.target {
  &:global(.react-flow__handle) {
    width: 0;
    height: 0;
    min-height: 0;
    min-width: 0;
    border: none;

    background-color: var(--dark-primary-color);
    z-index: 1001;

    filter: drop-shadow(0px 0px 10px rgba(200, 200, 200, 0.1));
    animation: box_shadow_animation 5s infinite;
    &:global(.react-flow__handle-bottom) {


    }
  }
}

@keyframes box_shadow_animation {
  0% {
    box-shadow: 0 5px 20px 10px var(--dark-primary-color);
  }
  50% {
    box-shadow: 0 5px 25px 15px var(--dark-primary-color);
  }
  100% {
    box-shadow: 0 5px 20px 10px var(--dark-primary-color);
  }
}
