.root {
  &:global(.react-flow__handle) {
    width: 12px;
    height: 12px;
    background-color: var(--edge-color);
    z-index: 1000;
    border: unset;

    &:global(.react-flow__handle-top) {
      top: -6px;
    }

    &:global(.react-flow__handle-right) {
      right: -6px;
    }

    &:global(.react-flow__handle-bottom) {
      bottom: -6px;
    }

    &:global(.react-flow__handle-left) {
      left: -6px;
    }
  }

  &.hidden {
    visibility: hidden;
  }

  &.hover {
    &:global(.react-flow__handle) {
      width: 12px;
      height: 12px;
      background-color: var(--tag-hover-color);
    }
  }
}

