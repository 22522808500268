.root {
  width: 100%;
  height: 100%;

  border-radius: 50%;

  background-color: var(--paper-color);
  color: var(--text-color);

  display: flex;
  justify-content: center;
  align-items: center;

  box-shadow: 0 5px 12px 0 rgba(13, 14, 18, 0.6980392157);

  cursor: pointer;

  &:hover {
    box-shadow: 0 8px 13px 0 #000000B2;
  }
}