.root {
  margin-left: 16px;

  display: flex;
  gap: 20px;
  align-items: center;
  width: 90%;
  overflow: hidden;

  .title {
    font-weight: 600;
    font-size: 1rem;
  }

  .type {
    font-weight: 400;
    color: var(--primary-color);

    text-transform: capitalize;
  }
}

