.large {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 120px;
  height: 56px;

  border: 1px solid var(--list-element-border-color);
  border-radius: 8px;

  cursor: pointer;

  font-weight: 600;
  line-height: 20px;

  text-align: center;

  padding: 8px 12px;

  background-color: var(--paper-color);

  &.inactive {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  & > * {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &.draggable {
    width: 100px;
    border-right: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:hover {
    background-color: var(--input-color);
    border: unset;

    & + .hook {
      border: unset;
    }
  }
}

.small {
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 20px;

  color: var(--label-text-color);
  background: var(--line-color);

  border-radius: 4px;

  display: flex;
  align-items: center;

  min-height: 20px;
  max-width: 588px;
  flex: 1;
  padding: 2px 4px;

  cursor: pointer;

  position: relative;
  overflow: hidden;

  &:focus-visible {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
    border-radius: 4px;
  }

  &.inactive {
    opacity: 0.3;

    &:hover {
      opacity: 1;
    }
  }

  .title {
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &.isHighlightManyToOne {
    background-color: #FF754C;

    p {
      color: white !important;
    }

    .MuiStack-root .MuiTypography-root .MuiTypography-body1 {
      p {
        color: white !important;
      }
    }
  }


  &:hover {
    background-color: var(--tag-hover-color);

    &.isHighlightManyToOne {
      background-color: #FF754C;

      p {
        color: white;
      }
    }

    & span {
      color: var(--paper-color);
    }
  }
}

.highlight {
  background-color: var(--tag-hover-color);
}

.hook {
  position: relative;
  bottom: 0;
  right: 0;

  width: 20px;


  border-radius: 0 8px 8px 0;
  border: 1px solid var(--list-element-border-color);
  border-left: 0;

  //noinspection CssInvalidFunction
  background-image: radial-gradient(var(--paper-color) 30%, var(--input-color) 40%);
  background-position: 4px 0;
  background-size: 6px 6px;

  z-index: 1000;

  cursor: pointer;
}

.button {
  color: var(--text-color)
}
