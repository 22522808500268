.root {
  position: absolute;
  transform: translate(-50%, 50%);
  top: 50%;
  left: 50%;
  animation: fade_animation 2s ease-out;
}

@keyframes fade_animation {
  0% {
    opacity: 0;
  }

  100% {

    opacity: 1;
  }
}