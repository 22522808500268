.canvas {
  background-color: var(--layout-color);
  color: var(--paper-color)
}

.controls {
  border-radius: 16px;
  display: flex;

  :global {

    .react-flow__controls-button {
      background-color: var(--paper-color);
      fill: var(--text-color);
      color: var(--text-color);

      padding: 12px;
      border: 2px solid var(--line-color);
      border-left: unset;
      border-right: unset;

      &[title] {
        content: '';
      }

      &:hover {
        background-color: var(--line-color);
      }
    }

    .react-flow__controls-zoomin {
      order: 1;
      border-radius: 16px 0 0 16px;
      border-left: 2px solid var(--line-color);
    }

    .react-flow__controls-scale {
      order: 2;
      cursor: default;

      &:hover {
        background-color: var(--paper-color);

      }
    }

    .react-flow__controls-zoomout {
      order: 3;
    }

    .react-flow__controls-fitview {
      order: 4;
    }

    .react-flow__controls-interactive {
      order: 5;
    }

    .react-flow__controls-details {
      order: 6;
      padding: 0;
      width: 40px;
      height: 40px;
      div {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .react-flow__controls-applyFlow {
      order: 7;
      border-radius: 0 16px 16px 0;
      border-right: 2px solid var(--line-color);
      background-color: var(--primary-color);
      transition: all .2s linear 0s;

      &:hover {
        background-color: var(--button-hover-color);
      }

      #applyChanges {

      }
    }

    .react-flow__controls-actualFlow {
      order: 6;
      border-radius: 0 16px 16px 0;
      border-right: 2px solid var(--line-color);
      cursor: default;

      &:hover {
        background-color: var(--paper-color);

      }
    }
  }
}

//:global(.react-flow__handle) {
//  position: absolute;
//  pointer-events: none;
//  min-width: 5px;
//  min-height: 5px;
//  width: 6px;
//  height: 6px;
//  background: #1a192b;
//  border: 1px solid white;
//  border-radius: 100%;
//}

//.miniMap {
:global(.react-flow__minimap) {
  background-color: var(--line-color) !important
}

//}
