.root {
  width: 100%;
  height: 100%;
  padding: 4px 4px 16px 4px;
  background-color: var(--paper-color);
  border: 1px solid #ccccdc12;
  border-radius: 4px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
