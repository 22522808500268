@mixin text400 {
  font-weight: 400;
  font-size: 1rem;
  font-family: "Montserrat", sans-serif;
}

@mixin text600 {
  font-family: "Inter", sans-serif;
  font-weight: 600;
  font-size: 0.875rem;
}