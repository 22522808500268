.root {
  :global {
    .MuiList-root {
      border-radius: 12px;

      background-color: var(--paper-color);

      display: grid;
    }

    .MuiMenuItem-root {
      white-space: unset;
      background-color: var(--paper-color);

      padding: unset;

      & > * {
        padding: 12px 16px;
        width: 100%;
      }

      &:hover {
        background-color: var(--list-element-border-color);
      }

      &.Mui-selected {
        background-color: var(--primary-color) !important;

        &:hover {
          background-color: var(--primary-color);
        }
      }
    }
  }
}

.paper {
  border-radius: 12px !important;


}