.root {
  position: fixed;
  right: 64px;
  bottom: 64px;
  z-index: 5;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  user-select: none;

  .title {
    font-size: 1rem;
    font-weight: 600;
    line-height: 20px;
  }
}