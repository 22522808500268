.root {
  transition: all 0.3s linear;
}

.root_selected {
  padding-top: 14px;
  padding-left: 14px;
}

.description {
  display: inline-block;
  color: #fff;
  opacity: 0.8;
  font-size: 0.875rem;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
  min-height: 24px;
}

.description_text {
  height: 18px;
  font-size: 12px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  opacity: 0.6;
}

.date {
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  opacity: 0.5;
}

.actionBar {
  height: 0px;
  transition: all 0.3s linear;
}

.actionBar_selected {
  height: 48px;
  animation: opacity_0_1 0.45s linear;
}

@keyframes opacity_0_1 {
  from {
    opacity: 0;
  }
  60% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}