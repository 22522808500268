.root {

  :global {
    .MuiDialog-paper {
      background-color: var(--paper-color);
      box-shadow: unset;
      background-image: unset;
      border-radius: 24px;
    }

    .MuiBackdrop-root {
      background-color: #1b1d2199;
    }

    .MuiDialogTitle-root {
      font-weight: 900;
      font-size: 1.125rem;
      padding: 28px 32px;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .MuiDialogContent-root {
      font-size: 1.125rem;
      font-weight: 400;
      line-height: 24px;
      padding: 0 32px;
    }

    .MuiDialogActions-spacing {
      padding: 32px;

      & > *:not(:first-of-type) {
        margin-left: 20px;
      }
    }
  }

  //position: fixed;
  //z-index: 1000;
  //right: 0;
  //bottom: 0;
  //top: 0;
  //left: 0;
  //display: flex;
  //align-items: center;
  //justify-content: center;
  //
  //& .content {
  //  min-width: 508px;
  //
  //  background-color: var(--paper-color);
  //  color: var(--text-color);
  //
  //  padding: 28px 32px 32px;
  //
  //  box-shadow: 4px 11px 20px 5px #0D0E12B2;
  //
  //  border-radius: 24px;
  //
  //  & .title {
  //    font-size: 1.125rem;
  //    font-weight: 900;
  //  }
  //
  //  & p {
  //    font-size: 1.125rem;
  //    font-weight: 500;
  //    line-height: 1.5rem;
  //  }
  //}
}