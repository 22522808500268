
.root {
  & > label {
    cursor: pointer;
  }
}

.radio {
  padding: 0 !important;
}


.icon {
  width: 16px;
  height: 16px;
  border: 2px solid var(--list-element-border-color);
  border-radius: 50%;
}

.checkedIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 2px solid var(--primary-color);
  border-radius: 50%;
  background-color: var(--primary-color);
}

.circle {
  width: 8px;
  height: 8px;
  background-color: var(--text-color);
  border-radius: 50%;
}