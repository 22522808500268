.root {
  min-width: 1200px;
  overflow: auto;
  height: 50vh;

  label {
    white-space: nowrap;
  }
}

.tabs {
  display: grid;
  grid-template-columns: repeat(6, auto);
  justify-content: center;
  gap: 32px;
}

.title {
  font-size: 16px;
}

.inactive {
  opacity: 0.1;
}