.wrapper {

  height: var(--header-height);

  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 60px;
  padding: 0 36px;

  background-color: var(--paper-color);

  border-bottom: 1px solid #373a43;
}