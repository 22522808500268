.root {
  min-width: 20px;
  height: 20px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 4px;
  border: 2px solid var(--label-text-color);

  color: var(--label-text-color);

  &:hover {
    color: var(--text-color);
    border: 2px solid var(--text-color);
    cursor: pointer;
    background-color: var(--list-element-border-color);
  }
  &:focus-visible {
    color: var(--text-color);
    border: 2px solid var(--text-color);
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
    border-radius: 4px;
    background-color: var(--list-element-border-color);
  }
}
