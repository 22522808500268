.splitPane {
  position: relative;
  height: 100%;
}


.togglepane {
  transition: width .7s ease;
}

.pane1 {
  overflow-x: hidden;
}

.resizer {
  background: var(--handle-color);
  z-index: 1050;
  height: 1px;

  &::before {
    position: relative;
    display: block;
    width: 15px;
    height: 50px;
    content: ' ';
    background: no-repeat center url("../../../assets/icons/3balls.svg") var(--handle-color);
    left: 50%;
    border: 1px solid var(--handle-color);
    border-radius: 0 5px 5px 0;
    transform: rotate(90deg) translate(-16px, 0) !important;
  }

  &.horizontal {
    height: 1px;
    cursor: pointer;
  }
}