.treeFlows {
  & :global(.ant-tree-switcher) {
    padding-top: 2px;
  }

  & :global(.ant-tree-node-content-wrapper) {
    overflow: hidden;
    margin-right: 10px;
    padding-bottom: .5em;
  }

  & :global(.ant-tree-node-content-wrapper .ant-tree-drop-indicator) {
    bottom: 3px!important;
    transform: translateX(26px);
  }

  & :global(.ant-tree-list-scrollbar-vertical) {
    width: 10px!important;

    &:hover :global(.ant-tree-list-scrollbar-thumb) {
      background-color: #b5b5b5!important;
      width: 6px!important;
    }

    & :global(.ant-tree-list-scrollbar-thumb) {
      background-color: #8d8d8d!important;
      width: 3px!important;
      right: 0px;

      &:global(.ant-tree-list-scrollbar-thumb-moving) {
        background-color: #ee784e!important;
        width: 6px!important;
      }
    }
  }
}
