.root {
  //box-shadow: none!important;
  border-radius: 8px 8px 8px 0!important;

  :global {
    .MuiTabs-root {
      min-height: 39px;
    }

    .MuiTab-root {
      min-height: 34px!important;
      margin: 5px 5px 1px 5px;
      text-transform: none;
      padding: 0 16px;
      border-radius: 8px 8px 8px 0;
      transition: 0.7s;

      &:hover {
        opacity: 1;
        transition: 0.3s;
      }
    }

    .MuiTabs-indicator {
      display: none;
    }

    .Mui-selected {
      background-color: var(--primary-color);
      color: var(--text-color);
      &:hover {
        transition: none;
      }
    }
  }
}