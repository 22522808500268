.input {
  border-radius: 8px;
  padding: 0 14px;
  border-width: 2px;

  :global(.ant-picker-range-separator) {
    display: flex;
    align-items: center;
    padding: 0 14px;
    color: var(--list-element-border-color);
  }

  :global(.ant-picker-active-bar) {
    background-color: var(--text-color) !important;
  }

  :global(.ant-picker-clear) {
    top: 50%;
    transform: translate(0%, -50%);
  }

  :global(.ant-picker-input) {
    & > input {
      background-color: var(--input-color);
      border-radius: 8px;
      border: 2px solid transparent !important;
      color: var(--text-color);
      font-size: 1rem;
      font-family: "Montserrat", sans-serif;
    }

  }
}

.popup {

  font-family: Inter sans-serif;
  box-shadow: unset !important;

  & :global .ant-picker-cell-today > div::before {
    border-color: var(--light-primary-color)!important;
  }

  & :global .ant-picker-footer .ant-picker-ok > button {
    box-shadow: none;
  }

  & :global .ant-picker-time-panel li.ant-picker-time-panel-cell-selected > div {
    background-color: var(--input-background)!important;
  }

}
