.root {
  width: 100%;
}

.paper {
  border-radius: 12px !important;
  margin-top: 8px;
  transition: all 0.2s linear 0s;
}

.warning {
  color: orange;
}

.labelBlock {
  display: flex;
  flex-direction: row;
}
