.root {
  padding: 32px;

  display: grid;
  grid-template-rows: max-content;
  
  overflow: hidden;

  .title {
    font-weight: 900;
    font-size: 1.125rem;
  }
}

