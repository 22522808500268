.root {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  height: 100%;
  gap: 30px;
  overflow-y: auto;
  padding: 0;
}

.box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-y: scroll;
}