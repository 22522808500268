.root {

  border-left: 1px solid var(--handle-color);

  position: relative;

  background-color: var(--layout-color);
  z-index: 1100;

  height: 100%;

  .handle {
    width: 15px;
    height: 50px;

    border: 1px solid var(--handle-color);
    border-radius: 5px 0 0 5px;
    background: no-repeat center url("../../../assets/icons/3balls.svg") var(--handle-color);

    position: absolute;
    left: 0;
    top: 50%;

    transform: translate(-100%, -50%) !important;

    cursor: pointer;
  }

  .content {
    position: relative;
    overflow: hidden;

    height: 100%;
  }
}
