@import '../../../assets/styles/mixins';

body[data-theme='dark'] {
  --switch-thumb-color: #808191;
  --switch-checked-thumb-color: #ffffff;
}

body[data-theme='light'] {
  --switch-thumb-color: #808191;
  --switch-checked-thumb-color: #ffffff;
}

.root {
  width: 36px;
  height: 20px;
  position: relative;
  display: flex;
  margin-right: 0;
  cursor: pointer;

  &.checked {
    & .thumb {
      // Вычитаю размер thumb и отступ
      left: calc(100% - 16px - 2px);
      top: 2px;
      background-color: var(--switch-checked-thumb-color);
    }

    .track {
      background: var(--primary-color);
      border: none;
    }
  }

  &:global(.Mui-focusVisible) {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
    border-radius: 16px;
  }

  .thumb {
    background-color: var(--switch-thumb-color);

    display: block;

    width: 16px;
    height: 16px;
    top: 2px;
    left: 2px;
    border-radius: 16px;
    position: relative;

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  .track {
    border: 1px solid var(--switch-thumb-color);
    display: block;
    border-radius: 16px;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  .input {
    cursor: inherit;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 0;
    z-index: 1;
    margin: 0;
  }
}

.label {
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
}

.switchLabel {
  @include text400;
  color: var(--text-color);
}
