.root {
  height: 100%;

  .body {
    overflow: auto;
    height: calc(100% - 60px);
  }

  & :global(.react-grid-placeholder) {
    background: var(--light-primary-color)!important;
    border: 1px solid #141414;
    color: wheat;
  }

  & :global(.recharts-wrapper) {
    width: 100%!important;
    height: 100%!important;
  }
}
