/* INTER */

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Thin.ttf') format('truetype');
  font-weight: 100;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Light.ttf') format('truetype');
  font-weight: 300;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Medium.ttf') format('truetype');
  font-weight: 500;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-SemiBold.ttf') format('truetype');
  font-weight: 600;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Bold.ttf') format('truetype');
  font-weight: 700;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-ExtraBold.ttf') format('truetype');
  font-weight: 800;
}

@font-face {
  font-family: 'Inter';
  src: url('../fonts/Inter/Inter-Black.ttf') format('truetype');
  font-weight: 900;
}

/* MONT */

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont/Mont-ExtraLight.ttf') format('truetype');
  font-weight: 200;
}

@font-face {
  font-family: 'Mont';
  src: url('../fonts/Mont/Mont-Heavy.ttf') format('truetype');
  font-weight: 900;
}

/* MONTSERRAT */

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../fonts/Montserrat/Montserrat-SemiBold.ttf') format('truetype');
  font-weight: 600;
}
