@import "../../../assets/styles/mixins";

:root {
  --input-color: #2a2d36;
}

body[data-theme='light'] {
  --input-color: #2a2d36;
}

.root {
  background: var(--input-color);
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 0 16px;
  border: 1px solid transparent;
  position: relative;

  &.error {
    border: 1px solid var(--error-color);

    &:focus,
    &:focus-visible {
      border: 1px solid var(--error-color);
    }
  }

  &:focus,
  &:focus-visible {
    border: 1px solid var(--line-color);
  }
}


.input {
  @include text400;

  border: 0;
  width: 100%;

  color: var(--text-color);
  line-height: 20px;

  &.endAdornment {
    right: 16px;
  }

  .adornment {
    position: absolute;
  }
}
