.root {

  background-color: var(--paper-color);

  z-index: 1000;

  display: flex;
  align-items: center;

  gap: 12px;
}