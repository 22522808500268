.title {
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--text-color);
}

.root{
  white-space: nowrap;
  max-width: 100%;
}