.root {

  :global {
    .rc-tree-switcher, .rc-tree-checkbox {
      background-image: none !important;
    }

    .rc-tree-switcher {
      margin-right: 8px !important;
    }

    .rc-tree-checkbox {
      margin: 0 -4px !important;
    }

    .rc-tree-iconEle {
      margin-right: 8px !important;
    }

    .rc-tree-node-content-wrapper {
      display: inline-flex !important;
      align-items: center !important;
    }

  }
}