.link {
  opacity: 0.5;
  transition: all .2s linear 0s;
  padding: 2px 0;
  position: relative;
  max-width: max-content;
  font-size: 0.75rem;

  &:hover {
    background-color: var(--input-color);
    opacity: 1;

    &:after {
      content: '';
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: var(--list-element-border-color);
      display: block;
    }
  }

}