.root {
  padding: 32px;
  gap: 40px;
  width: 100%;
  border: 1px solid var(--line-color);
  border-radius: 24px;

  &.disabled {
    cursor: not-allowed;
    pointer-events: none;
    opacity: 0.4;
  }

  .title {
    font-weight: 900;
    font-size: 1.125rem;
    line-height: 23px;
    color: var(--text-color);
  }
}