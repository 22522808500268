.root {
  padding: 32px;
  gap: 40px;
  width: 100%;


  border: 1px solid var(--line-color);
  border-radius: 24px;

  .title {
    font-weight: 900;
    font-size: 1.125rem;
    line-height: 23px;
    color: var(--text-color);

    &.error {
      color: var(--error-color)
    }
  }

  .editor {
    span {
      font-family: Consolas, "Courier New", monospace;
    }
  }
}


