.button {
  width: max-content;

  padding: 8px 12px;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.75rem;
  font-weight: 500;
  line-height: 20px;

  color: var(--text-color);

  border-radius: 12px;

  cursor: pointer;

  transition: all .2s linear 0s;

  position: relative;

  &.active {
    background-color: var(--primary-color);
  }

  &.marker {
    &.active {
      &:after {
        content: '';

        width: 4px;
        height: 4px;

        background-color: var(--error-color);

        display: block;

        position: absolute;
        right: -2px;
        top: 0;

        border-radius: 50%;
      }
    }
  }

  &:hover {
    background-color: var(--primary-color);
  }
}

.menu {
  z-index: 1500;

  padding: 4px;
  margin-top: 4px !important;

  border-radius: 12px;
  border: 1px solid var(--list-element-border-color);

  background-color: var(--paper-color);

  max-height: 200px;
  max-width: 272px;
  width: 100%;

  overflow-y: auto;


  & > ul {
    display: flex;
    flex-direction: column;
    gap: 12px;


    & > li {
      color: var(--text-color);

      cursor: pointer;

      min-height: 24px;

      display: flex;
      align-items: center;

      line-height: 16px;

      padding: 16px 20px;

      border-radius: 12px;

      transition: all .2s linear 0s;


      &:hover {
        background-color: var(--list-element-border-color);
      }
    }
  }
}