.root {
  .title {
    font-weight: 900;
    font-size: 1.125rem;
    line-height: 23px;
    color: var(--text-color);
  }
  .tabs {
    //box-shadow: none!important;
    border-radius: 8px 8px 8px 0!important;

    :global {
      .MuiTabs-root {
        min-height: 39px;
        .MuiTabs-flexContainer {
          padding: 5px 5px 0 5px;
        }
      }

      .MuiTab-root {
        min-height: 34px!important;
        margin-left: 2px;
        text-transform: none;
        padding: 0 .5em 0 1em;
        border-radius: 8px 8px 0 0;
        transition: 0.7s;
        justify-content: space-between;

        &:hover {
          opacity: 1;
          transition: 0.3s;
        }

        &.add-trigger {
          padding: 0;
          min-width: 3em;
          border-radius: 8px;
        }
      }

      .MuiTabs-indicator {
        display: none;
      }

      .Mui-selected {
        background-color: var(--primary-color);
        color: var(--text-color);
        &:hover {
          transition: none;
        }
      }
    }
  }
}