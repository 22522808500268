.description {
  font-size: 1rem;
  font-weight: 600;
  line-height: 20px;
  cursor: default;
}

.create-btn {
  width: 72px;
  height: 72px;
  right: 64px;
  bottom: 64px;

  &:global.ant-float-btn {
    .ant-float-btn-body:hover {
      background-color: var(--button-hover-color);
    }

    .ant-float-btn-body .ant-float-btn-content .ant-float-btn-icon {
      width: auto;
    }
  }
}
