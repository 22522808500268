.root {

  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }
}

.checkbox {
  padding: 0 !important;
}

.disabledCheckbox {
  border: 2px solid var(--list-element-border-color);
  background-color: var(--list-element-border-color);
  cursor: not-allowed;
}

.icon {
  width: 16px;
  height: 16px;
  border: 2px solid var(--list-element-border-color);
  border-radius: 4px;

  &.disabled {
    @extend .disabledCheckbox
  }
}

.checkedIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  border: 2px solid var(--primary-color);
  border-radius: 4px;
  background-color: var(--primary-color);

  &.disabled {
    @extend .disabledCheckbox
  }
}