.root {
  .title {
    font-weight: 900;
    font-size: 1.125rem;
  }

  .date {
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 18px;
  }
}