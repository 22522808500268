.root {
  display: grid;
  grid-template-columns: max-content 1fr;
  position: relative;
  height: calc(100vh - 96px);
}

.splitter {
  height: calc(100vh - 96px) !important;
}

.pane1 {
  overflow-x: hidden;
}

.togglepane {
  transition: width .7s ease;
}

.resizer {
  background: var(--handle-color);
  z-index: 1050;


  &::before {
    position: relative;
    display: block;
    width: 15px;
    height: 50px;
    content: ' ';
    background: no-repeat center url("../../../../assets/icons/3balls.svg") var(--handle-color);
    top: 50%;
    border: 1px solid var(--handle-color);
    border-radius: 0 5px 5px 0;
    transform: translate(0, -50%) !important;
  }

  &.vertical {
    width: 0px;
    cursor: pointer;
  }
}