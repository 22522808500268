.root {
  border-right: 1px solid var(--line-color);
  height: 100%;

  & .title {
    font-size: 12px;
    color: var(--label-text-color);
    font-weight: 500;
    line-height: 16px
  }


}