.root {
  overflow: auto;

  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 12px;
  padding: 12px 0 !important;

  max-width: calc(100% - 6px);
  width: 100%;
}