.icon {
  color: var(--text-color);
}

.listbox {
  padding: 16px 0 16px 0;
  border-radius: 8px;
  border: 1px solid var(--list-element-border-color);
  background-color: var(--paper-color);
  width: 100%;
}

.option {
  color: var(--text-color);
  cursor: pointer;
  min-height: 24px;
  display: flex;
  align-items: center;
  line-height: 16px;
  padding: 12px 20px;
  width: 100%;
  line-break: anywhere;
  font-size: 0.875rem;

  &:hover {
    background-color: var(--list-element-border-color);
    cursor: pointer;
  }
}

.autocomplete {
  :global(.MuiAutocomplete-tag) {
    overflow: hidden;
    min-width: 50px;
    max-width: calc(100% - 80px) !important;
  }

  :global(.MuiAutocomplete-inputRoot .MuiAutocomplete-input) {
    min-width: unset !important;
  }

  :global(.MuiFormControl-root) {
    height: unset;
  }
}
