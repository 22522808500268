.root {
  display: flex;
  flex-direction: row;
  gap: .5rem;

  & > button, & :global(.zoom-btn) {
    width: auto;
    color: var(--label-text-color);
    border-color: #5c647d!important;
  }

  .time-manager-btn {
    display: flex;
    justify-content: start;
    min-width: 240px;
    height: 48px;
    line-height: 1.25rem;
    font-size: 0.875rem;

    &:global(.mode-history) {
      min-width: 350px;
    }

    & > span:nth-last-child(2) {
      flex-grow: 1;
      text-align: left;
    }
  }

  .refresh-btn > button {
    height: 48px;
    line-height: 1.25rem;
    font-size: 0.875rem;
    color: var(--label-text-color);

    &:global(.ant-dropdown-trigger) {
      width: 70px;
    }
  }
}

.popover :global {
  .ant-popover-inner-content {
    display: flex;
    width: 400px;
    gap: 1em;

    .picker {
      display: flex;
      flex-direction: column;
      gap: .25em;
      height: 4.5em;
      padding: 0;

      & > .ant-picker-input {
        & > .ant-input {
          padding: 4px 11px;
          border-radius: 6px;
          transition: all 0.2s;
        }

        & > .ant-input-outlined {
          border: solid 1px #5c647d;
          background: #242731;

          &:hover {
            border-color: #897ccc;
            background-color: #242731;
          }
        }
      }
      & > .ant-picker-input-active {
        & > .ant-input {
          border-color: #897ccc;
          box-shadow: 0 0 6px #897ccc;
        }
      }
      & > .ant-picker-active-bar {
        display: none;
      }
    }

    .periods {
      width: 65%;

      .period-list {
        height: 300px;
        overflow: auto;
        margin-top: .5em;
        padding-right: .5em;

        .ant-list-item {
          border-radius: 6px;

          &.active {
            background: #5f52b6;
          }
          &:hover {
            box-shadow: 0 0 6px inset #897ccc;
            opacity: 0.8;
          }
        }
      }
    }
  }
}