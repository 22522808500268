.root {
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

.filter {
  margin: 0 0 16px 0;
  display: flex;
}

.content {
  flex-grow: 1;
  flex-basis: 100px;
  overflow-y: auto;
  margin-right: -8px;
  padding-right: 8px;
  padding-bottom: 100px;
}

.addCardPanel {
  margin: 0 -30px -30px -30px;
  width: calc(100% + 60px);
  height: 100px;
  position: absolute;
  bottom: 0;
  opacity: 0.85;
  backdrop-filter: blur(16px);
  z-index: 1200;
}

.addCard {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 300px;
  display: flex;
  z-index: 1201;
  opacity: 1;
}