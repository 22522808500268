.label {
  position: absolute;
  background-color: var(--tag-hover-color);
  padding: 12px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  z-index: 1500;
  color: var(--paper-color);
  display: flex;
  gap: 20px;
  white-space: pre-wrap;
  line-height: 20px;
}

.edge {
  stroke-width: 2px;
  stroke: var(--edge-color);
  fill: none;

  &.manyToOne {
    stroke: yellow;
  }

  &.hover {
    stroke: var(--tag-hover-color);
  }
}
