:root {
  --button-hover-color: #443D7A;
  --button-active-color: #9789FB;
  --button-disable-color: #373A43;
  --button-disable-text-color: #FFFFFF33;
  --blue-button-color: #0049C6;
  --blue-button-hover-color: #3F8CFF;
  --blue-button-active-color: #244CB2;
  --blue-button-disable-color: #0049C6;
  --outlined-button-color: #FFFFFF33;
  --outlined-button-text-color: #666666;
  --outlined-button-disabled-text-color: #333;
  --secondary-button-active-color: #1E2129;
}

body[data-theme='light'] {
  --button-hover-color: #443D7A;
  --button-active-color: #9789FB;
  --button-disable-color: #373A43;
  --button-disable-text-color: #FFFFFF33;
  --blue-button-color: #0049C6;
  --blue-button-hover-color: #3F8CFF;
  --blue-button-active-color: #244CB2;
  --blue-button-disable-color: #0049C6;
  --outlined-button-color: #FFFFFF33;
  --outlined-button-text-color: #666666;
  --outlined-button-disabled-text-color: #333;
  --secondary-button-active-color: #1E2129;
}

.root {
  width: 100%;
  height: 56px;

  border-radius: 16px;
  box-sizing: border-box;
  border: 1px solid transparent;

  cursor: pointer;

  color: var(--text-color);

  font-weight: 700;
  line-height: 1.25rem;
  font-size: 0.875rem;

  transition: all .2s linear 0s;

  display: flex;
  justify-content: center;
  align-items: center;

  &.inFormOrTable {
    border-radius: 8px;
  }

  &.primary {
    background-color: var(--secondary-color);

    &:hover {
      background-color: var(--button-hover-color);
    }

    &:active {
      background-color: var(--button-active-color);
    }

    &:disabled {
      background-color: var(--button-disable-color);
      color: var(--button-disable-text-color);
    }
  }

  &.secondary {
    background-color: var(--paper-color);
    border-color: var(--list-element-border-color);

    &:hover {
      background-color: var(--input-background);
      border-color: var(--list-element-border-color);
    }

    &:active {
      background-color: var(--secondary-button-active-color);
      border-color: var(--list-element-border-color);
    }

    &:disabled {
      background-color: var(--paper-color);
      color: var(--button-disable-text-color);
      border-color: var(--list-element-border-color);
    }
  }

  &.popup {
    position: relative;
    background-color: var(--primary-color);

    &:hover {
      background-color: var(--button-hover-color);
    }

    &:active {
      background-color: var(--button-active-color);
    }

    &:disabled {
      background-color: var(--button-disable-color);
      color: var(--button-disable-text-color);
    }
  }


  &.blue {
    background-color: var(--blue-button-color);

    &:hover {
      background-color: var(--blue-button-hover-color);
    }

    &:active {
      background-color: var(--blue-button-active-color);
    }

    &:disabled {
      background: var(--blue-button-disabled-color);
    }
  }

  &.text {
    &:hover {
      background-color: var(--list-element-border-color);
    }
  }

  &.outlined {
    background-color: transparent;
    color: var(--text-color);
    opacity: .4;
    box-sizing: border-box;
    box-shadow: none;
    border: 1px solid var(--line-color);


    &:hover {
      background-color: var(--outlined-button-color);
      opacity: unset;
    }

    &.active,
    &:active {
      background-color: transparent;
      border: 1px solid var(--text-color);
      opacity: unset;
    }

    &:disabled {
      background-color: transparent;
      opacity: .2;
      border: none;
    }
  }

  &.circle {
    min-width: 72px;
    width: max-content;
    height: 72px;

    border-radius: 50%;

    font-size: 26px;


    align-items: center;

    border: unset;
    padding: unset !important;

    &.small {

      & > svg {
        width: 16px;
        height: 16px;
      }

      width: 32px;
      min-width: auto;
      height: 32px;
    }
  }

  &:hover {
    background-color: var(--button-hover-color);
  }

  &:disabled {
    background: var(--button-disable-color);
    color: var(--button-disable-text-color);

    cursor: not-allowed;
  }

  &:focus-visible {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
    border-radius: 50%;
    opacity: 1;
  }

  &:focus-visible {
    outline: 2px solid var(--primary-color);
    outline-offset: 2px;
    border-radius: 16px;
    opacity: 1;
  }


  &.small {

    & > svg {
      width: 16px;
      height: 16px;
    }

    padding: 0 20px;
    min-width: auto;
    height: 32px;
  }

  &.medium {
    & > svg {
      width: 20px;
      height: 20px;
    }

    min-width: 48px;
    height: 48px;
  }
}
