.root {
  padding: 10px;
  border: 1px solid #32353F;
  border-radius: 8px;


  .description {
    font-weight: 400 !important;
    line-height: 16px !important;
    min-height: 16px !important;
  }

}

