.root {
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;

  color: var(--input-label-text-color);

  &.required {
    &:after {
      content: '*';
      color: var(--error-color);
      margin-left: 4px;
    }
  }

  &.error {
    color: var(--error-color);
  }

  &.warning {
    color: var(--toastify-color-warning);
  }
}

.tooltipIcon {
  color: var(--input-label-text-color);

  &.error {
    color: var(--error-color);
  }
}