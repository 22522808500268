
.disabled {
  opacity: 0.3;

  input {
    cursor: not-allowed !important;
  }

  &.label {
    cursor: not-allowed;
  }
}

.label {
  font-weight: 600;
  line-height: 20px;
  cursor: pointer;
  font-size: 0.875rem;
  color: var(--text-color);
}


