.menu {
  z-index: 1500;

  padding: 4px;
  margin-top: 4px !important;

  border-radius: 12px;
  border: 1px solid var(--list-element-border-color);

  background-color: var(--paper-color);

  max-height: 200px;
  max-width: 228px;
  width: 100%;

  overflow-y: auto;

  .active {
    background-color: var(--primary-color);

    &:hover {
      background-color: var(--primary-color);
    }
  }


  & > ul {
    display: flex;
    flex-direction: column;
    gap: 12px;


    & > li {
      color: var(--text-color);

      cursor: pointer;

      min-height: 24px;

      display: flex;
      align-items: center;

      line-height: 16px;

      padding: 16px 20px;

      border-radius: 12px;

      transition: all .2s linear 0s;

      font-weight: 600;


      &:hover {
        background-color: var(--list-element-border-color);
      }
    }
  }
}