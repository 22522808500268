.root {
  .description {
    height: 16px;
    font-size: 12px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    opacity: 0.4;

    &.active {
      opacity: 1;
      height: 20px;
    }
  }

  .date {
    font-weight: 400;
    font-size: 10px;
    line-height: 16px;
    opacity: 0.5;
    padding-top: 15px;
  }
}
