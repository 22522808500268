.grid {
  display: grid;
}

@for $i from 1 through 12 {
  .grid-cols-#{$i} {
    grid-template-columns: repeat(#{$i}, 1fr);
  }
}

.col-auto {
  grid-column: auto;
}

@for $i from 1 through 12 {
  .col-span-#{$i} {
    grid-column: span #{$i} / span #{$i};
  }
}

.col-span-full {
  grid-column: 1 / -1;
}

@for $i from 1 through 60 {
  .gap-#{$i} {
    gap: #{0.25 * $i}rem;
  }

  .gap-x-#{$i} {
    column-gap: #{0.25 * $i}rem;
  }
  .gap-y-#{$i} {
    row-gap: #{0.25 * $i}rem;
  }
}

@for $i from 1 through 60 {
  .p-#{$i} {
    padding: #{0.25 * $i}rem;
  }

  .px-#{$i} {
    padding-right: #{0.25 * $i}rem;
    padding-left: #{0.25 * $i}rem;
  }

  .py-#{$i} {
    padding-top: #{0.25 * $i}rem;
    padding-bottom: #{0.25 * $i}rem;
  }

  .pt-#{$i} {
    padding-top: #{0.25 * $i}rem;
  }

  .pl-#{$i} {
    padding-left: #{0.25 * $i}rem;
  }

  .pb-#{$i} {
    padding-bottom: #{0.25 * $i}rem;
  }

  .pr-#{$i} {
    padding-right: #{0.25 * $i}rem;
  }
}

@for $i from 1 through 60 {
  .m-#{$i} {
    margin: #{0.25 * $i}rem;
  }

  .mx-#{$i} {
    margin-right: #{0.25 * $i}rem;
    margin-left: #{0.25 * $i}rem;
  }

  .my-#{$i} {
    margin-top: #{0.25 * $i}rem;
    margin-bottom: #{0.25 * $i}rem;
  }

  .mt-#{$i} {
    margin-top: #{0.25 * $i}rem;
  }

  .ml-#{$i} {
    margin-left: #{0.25 * $i}rem;
  }

  .mb-#{$i} {
    margin-bottom: #{0.25 * $i}rem;
  }

  .mr-#{$i} {
    margin-right: #{0.25 * $i}rem;
  }
}


@for $i from 1 through 12 {
  .col-start-#{$i} {
    grid-column-start: #{$i};
  }
}


.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.justify-normal {
  justify-content: normal;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-stretch {
  justify-content: stretch;
}

.flex {
  display: flex;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.self-baseline {
  align-self: baseline;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.invisible {
  visibility: hidden;
}

.opacity-0 {
  opacity: 0;
}

.opacity-40 {
  opacity: 0.4;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-100 {
  opacity: 1;
}

.cursor-pointer {
  cursor: pointer;
}