.root {
  padding: 12px 20px;
  position: relative;
  cursor: pointer;

  .title {
    display: flex;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: var(--label-text-color);

    &:hover {
      color: var(--text-color);
    }
  }

  .line {
    height: 4px;
    width: 100%;
    border-radius: 2px 2px 0 0;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &.active {
    .line {
      background-color: var(--text-color);
    }

    .title {
      color: var(--text-color);
    }
  }
}